<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                배너
              </h4>
              <p class="card-title-desc">현재 등록된 배너를 확인합니다.</p>

              <div class=" mg-b-10 d-flex justify-content-between">
                <button
                  class="btn btn-info btn-sm"
                  type="button"
                  @click="$router.push('/banner/add')"
                >
                  등록
                </button>
                <!-- <form class="d-flex" @submit.prevent="getPaymentListSearch">
                  <select class="form-control mg-r-5">
                    <option value="">뱃지</option>
                    <option value="">제목</option>
                  </select>
                  <b-form-input
                    id="inline-form-input-name"
                    v-model="serachKeyword"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="검색어"
                  ></b-form-input>
                  <button class="btn btn-primary btn-sm" type="submit">
                    검색
                  </button>
                </form> -->
              </div>
              <!-- table -->
              <div class="table-responsive">
                <table class="table table-hover mb-0 text-center">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>위치</th>
                      <th>뱃지</th>
                      <th>제목</th>
                      <th>정렬</th>
                      <th>사용여부</th>
                      <th>등록일</th>
                      <th>관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in list" :key="item.idx">
                      <th scope="row">{{ startIndx - index }}</th>
                      <td>{{ item.type }}</td>
                      <td>
                        <span :class="`badge bg-${item.className}`"> {{ item.badgeTitle }} </span>
                      </td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.orderNum }}</td>
                      <td>{{ item.openYn }}</td>
                      <td>{{ item.regdate | moment('YY-MM-DD') }}</td>
                      <td>
                        <button
                          class="btn btn-primary btn-sm"
                          @click="$router.push(`/banner/modify/${item.idx}`)"
                        >
                          수정
                        </button>
                        <button class="btn btn-danger btn-sm ms-2" @click="deleteBanner(item.idx)">
                          삭제
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- //end table -->
              <b-pagination
                v-model="pageData.page"
                :total-rows="pageData.totalCnt"
                :per-page="pageData.perPage"
                align="center"
                aria-controls="my-table"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
</template>
<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Banner',
    meta: [{name: 'description', content: appConfig.description}]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: 'Banner',
      items: [
        {
          text: 'Banner',
          href: '/banner'
        },
        {
          text: 'Banner Lists',
          active: true
        }
      ],
      list: '',
      serachKeyword: '',
      pageData: {
        page: 1,
        perPage: 15,
        totalCnt: 100
      }
    };
  },
  created() {
    this.getLoadData();
  },
  methods: {
    async getLoadData() {
      const res = await this.$store.dispatch('banner/bannerList', {
        page: this.pageData.page,
        limit: this.pageData.perPage
      });

      this.list = res.data;
      this.pageData.totalCnt = res.totalCnt;
      this.startIndx = res.totalCnt - (this.pageData.page - 1) * this.pageData.perPage;
      console.log(this.list);
    },
    deleteBanner(id) {
      this.$confirm({
        message: `배너를 삭제하시겠습니까?`,
        button: {
          no: '취소',
          yes: '삭제'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: async confirm => {
          if (confirm) {
            await this.$store
              .dispatch('banner/bannerDelete', {
                idx: id
              })
              .then(res => {
                this.$confirm({
                  title: res.result ? '삭제완료' : '삭제실패',
                  message: res.message,
                  button: {
                    yes: '확인'
                  }
                });
                if (res.result) {
                  this.getLoadData();
                }
              });
          }
        }
      });
    }
  }
};
</script>
<style scoped>
table th,
table td {
  text-align: center;
}
form {
  justify-content: flex-end;
}
form input,
form select {
  width: 100px;
  min-width: 80px;
  margin-right: 10px;
}
form input {
  width: 200px;
}
form button {
  min-width: 50px;
}
</style>
